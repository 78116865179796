const hungarianStrings = {
YourShipmentHasBeenDelivered: 'A küldeményét kézbesítették', 
DeliveredBy: 'Kézbesítette:', 
RateYourExperience: ' Értékeld szolgáltatásunkat', 
ThankYouForYourValuableFeedback: 'Köszönjük értékes visszajelzését', 
YourFeedbackWillContinuallyHelpUsImproveOurServices: 'Visszajelzései folyamatosan segítik, hogy  javítani tudjuk a szolgáltatásainkat', 
DeliveryFailed: 'A kézbesítés sikertelen', 
AddDeliveryPreferences: 'Kézbesítési beállítások hozzáadása', 
YourDeliveryPreferenceAreSavedSuccessFully: 'Az Ön szállítási preferenciáját sikeresen és Teljesen elmentettük', 
WeAreUnableToTrackYourShipmentRightNow: 'Jelenleg nem tudjuk nyomon követni a küldeményét', 
PleaseUpdateLocationInsideCircle: 'Kérjük, frissítse a helyszínt a körön belül', 
Ok: 'Rendben', 
Update: 'Frissítés', 
PickCurrentLocation: 'Válassza ki a jelenlegi  helyet', 
SearchFor: '                 keresése', 
ThisSchedulingLinkHasExpired: 'Ez az ütemezési link lejárt', 
WeWillShareANewLinkWithYouShortly: 'Hamarosan új linket osztunk meg Önnel', 
UhHo: 'Aha, ó!', 
NeedHelp: 'SEGÍTSÉGRE VAN SZÜKSÉGE?', 
CallSupport: 'Hívja az ügyfélszolgálatot', 
EmailSupport: 'Küldjön emailt az ügyfélszolgálatnak', 
DeliveryAt: 'Szállítás a       címre', 
ContactNo: 'Elérhetőségi szám', 
TrackOnMap: 'Nyomon követés a térképen', 
MessageToExecutive: 'Üzenet a vezetőnek', 
Cancel: 'Törlés', 
Send: 'Küldés', 
Executive: 'Vezető', 
Comments: 'Megjegyzések (opcionális)', 
SubmitFeedback: 'VISSZAJELZÉS KÜLDÉSE', 
TrackingNo: 'Nyomon követési szám', 
Details: 'Részletek', 
Characters: 'karakter', 
Updating: 'frissítés.....', 
OopsSomethingWentWrongPleaseTryAfterSomeTime: 'Hoppá! Valami elromlott, kérjük, próbálja meg egy kis idő múlva.', 
NotListedAboveSendACustomMessage: 'Fent nem szerepel, küldjön egyéni üzenetet', 
ChangeDeliveryLocation: 'Kiszállítási hely módosítása', 
AttemptedBy: 'A futár:', 
DeliveredTo: 'Kézbesítve ide:         ', 
SendMessage: 'ÜZENET KÜLDÉSE', 
VIEW_DETAILS: 'Részletek megtekintése', 
SAVE_LOCATION: 'HELYSZÍN MENTÉSE', 
LOCATION: 'helyszín', 
SEARCH_ADDRESS: 'Cím keresése', 
OpenHours: 'Nyitvatartási idő', 
Note: 'Megjegyzés', 
Address: 'Cím', 
Nearest: 'Legközelebbi', 
ContactNumber: 'Elérhetőségi szám', 
FAQS: 'GYIK', 
HowManyTimesCanIChangeMyPickUpPoint: 'Hányszor változtathatom meg az átvételi pontot?', 
YouCanChangeItOnly: 'Csak                  változtathatja meg', 
GoBack: 'Vissza', 
SetAsPickupStore: 'Átvételi üzletként beállítva', 
MapView: 'Térképes nézet', 
SearchForPickupPointNearYou: 'Keressen egy közeli átvételi pontot', 
Arriving: 'Érkezés', 
LiveTrackingWillStart: 'Elindul az élő nyomon követés', 
DriverTrackerWillGetActivatedOnceTheyAreOnWay: 'A gépkocsivezető nyomonkövetője aktiválódik, mihelyt útnak indul', 
TrackerTimeline: 'Nyomon követési  idővonal', 
PM: 'du.', 
AM: 'de.', 
TodayAt: 'ma:', 
TomorrowAt: 'holnap órakor', 
Today: 'ma:', 
Tomorrow: 'Holnap', 
On: '        -án/-én', 
In: ':', 
Soon: 'hamarosan', 
Min: 'perc', 
Mins: 'perc', 
Items: 'TÉTELEK', 
ViewDetails: 'Részletek megtekintése', 
DeliveryInformation: 'Szállítási adatok', 
TrackingHistory: 'Követési előzmények', 
StayConnected: 'Maradjon kapcsolatban velünk', 
YouHaveAlreadyAttemptedToMakeAPaymentInLastFewMinutes: 'Az elmúlt néhány percben már megpróbált fizetni. Szeretné törölni a korábbi tranzakciókat', 
No: 'NEM', 
yes: 'IGEN', 
YourPackageWillBeDeliveredBy: 'A csomagját               fogja kiszállítani', 
OrderDetails: 'A megrendelés részletei', 
OrderInformation: 'Megrendelési adatok', 
Thankyou: 'Köszönöm', 
LastAttempted: 'Utolsó próbálkozás', 
DeliveryBy: 'Kiszállítja:', 
MsgToFePageHeader: 'Szállítási utasítások küldése', 
MsgToFeSupportingText: 'Mit szeretne közölni a gépkocsivezetővel?', 
AddYourMsgHere: ' Írd ide az üzeneted', 
InstructionsSentSuccessfully: 'Sikeresen elküldött utasítások', 
SomethingWentWrongPlsTryAgain: 'Valami rosszul sült el, kérjük, próbálja meg újra.', 
times: '         -szor/-szer/-ször', 
TrackMovementInRealTime: ' A teherautó mozgásának követése valós időben', 
SetAsPickupStore: 'Átvételi üzletként beállítva', 
MapView: 'Térképes nézet', 
DeliveredAt: 'A szállítás időpontja:', 
LastAttempted: 'Utolsó próbálkozás', 
Tomorrow: 'Holnap',
WriteYourFeedbackOptional:"Írja meg visszajelzését (opcionális)", 
ReturnOrder: "Return Order",
TheLinkYouAreTryingToOpenHasExpired: "A megnyitni kívánt link lejárt",
SelectAnAvailableTimeSlot:"Select an available time slot",
January:"JANUARY",
February:"FEBRUARY",
March :"MARCH",
April:"APRIL",
May:"MAY",
June:"JUNE",
July:"JULY",
August:"AUGUST",
September:"SEPTEMBER",
October:"OCTOBER",
November:"NOVEMBER",
December:"DECEMBER",
Monday:"MONDAY",
Tuesday:"TUESDAY",
Wednesday:"WEDNESDAY",
Thursday:"THURSDAY",
Friday:"FRIDAY",
Saturday:"SATURDAY",
Sunday:"SUNDAY",
ViewTimings: "View Timings",
HideTimings: "Hide Timings",
PleaseAnswerAllMandatoryQuestions:"Kérem, válaszoljon minden kötelező kérdésre",
HelpLineLink: 'Helpline Link',
ShipmentOf: "Küldemény%/",
OtherShipments: "Egyéb Küldemények",
}
export default hungarianStrings;