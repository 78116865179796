import React, { Component } from "react";


import '../LayoutScreen/style.css';
import OrderInformation from "../../components/OrderInformation";
import { bindActionCreators } from "redux";
import '../../style';

import { connect } from "react-redux";
import * as CustomerInteractionAction from "../../CustomerInteractionActions";

import Grid from "@material-ui/core/Grid";
import FieldExecutiveDetail from "../../components/FieldExecutiveDetail";
import Banner from "../../components/Banner";
import Carousel from "../../components/Carousel";
import SocialMedia from "../../components/SocialMedia";
import OrderTracking from "../OrderTracking";
import { processUpdateFormService } from "../../services/ProcessUpdateFormService";
import * as LivetrackingAction from "../LiveTracking/LiveTrackingAction";
import EmbedTracking from "../LiveTracking/EmbedTracking";
import { isEmpty, cloneDeep } from "loadsh";
import MessageToFE from '../../components/MessageToFE'
import Snackbar from '@material-ui/core/Snackbar';
import ArrivingEta from "../../components/ArrivingEta";
import FeedbackV3 from "../FeedbackV3";
import ReturnInstruction from "../../components/ReturnInstruction";
import 'antd/dist/antd.css';
import { Carousel as Carousel1 } from 'antd';
// import { RightOutlined } from '@ant-design/icons'
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { isEqual } from "lodash";
import IframeGoogleDocs from "../../components/GoogleDocViewer";
import { mediaLinkService } from "../../services/MediaLinkService";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
// import CIPudoReturnMap from "../../containers/LayoutScreen/PudoReturnMap/CIPudoReturnMap";
// import CIPudoReturnMapList from "../../containers/LayoutScreen/PudoReturnMap/CIPudoReturnMapList";
import CIPudoReturnStoreDetails from "../LayoutScreen/PudoReturnMap/CIPudoReturnStoreDetails";
import MobileQuickAction from "../../components/QuickAction/MobileQuickAction";
import PudoMobileViewDetails from "../../components/PudoStoreDetails/pudoMobileViewDetails";
import { getPudoData } from "../MapView/MapViewAction"
import TableLoader from "../../components/TableLoader";
import { commonUtilities } from "../../services/commonUtilities";
import AddressDetails from "../../components/AddressDetails";
import ShipmentList from "../../components/Shipment";
import OrderDetails from "../../components/Shipment/details";
import NewHelpSection from "../../components/NewHelpSection";

const contentStyle = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};

class NewMobileViewOrder extends Component {
  messageToFE = "";
  monthNames = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  constructor(props) {
    super(props);
    this.state = {
      eventScreen: false,
      layoutScreen: false,
      message: false,
      marketingAssets: {},
      trackEnableTime: -1,
      isTripAPICalled: false,
      isEmbed: false,
    };
    // this.setMessageModal = this.setMessageModal.bind(this);
    this.getRefereshData = this.getRefereshData.bind(this);
    this.trackingDetailsRefreshTimeout = null;
  }
  componentWillMount() {
    if (window.location.href.indexOf('&embed=true') != -1) {
      this.setState({
        isEmbed: true,
      })
    }
  }
  async componentDidMount() {
    document.body.style.overflowY = "auto";
    this.intervalId = setInterval(this.getRefereshData.bind(this), 5 * 60000);
    if (!this.props.trackingDetails.calledFromDiy) {
      const marketingAssets = await processUpdateFormService.getMarketingAssets(this.props.url, null, this.props.isReturn);
      if (!isEmpty(marketingAssets)) {
        this.setState({ marketingAssets: marketingAssets });
      }
    }

    processUpdateFormService.checkGoogleAnalyticsCode(this.props.trackingDetails);
    this.checkPudoWithQuickAction();

    if (!isEmpty(this.props.trackingDetails.liveTrackingPreviousJobsInfo) && this.props.trackingDetails.liveTrackingPreviousJobsInfo.enabled) {
      this.previousJobsInfoIntervalId = setInterval(() => this.props.actions.getPreviousJobsInfo(this.props.url, this.props.trackingDetails), 60000);  // 60 sec

    }

  }

  checkPudoWithQuickAction = () => {
    if (this.props.trackingDetails && this.props.trackingDetails.customerInteractionThemeSettingsDTO
      && this.props.trackingDetails.customerInteractionThemeSettingsDTO.quickAction
      && this.props.trackingDetails.pudo && this.props.trackingDetails.pudo.pudoScreen === true
      && isEmpty(this.props.pudoPoints)) {
      this.props.actions.getPudoData(
        this.props.url,
        this.props.trackingDetails.jobLat,
        this.props.trackingDetails.jobLng,
        this.props.trackingDetails.masterCode
      );
    }
  }

  async componentDidUpdate(nextProps) {
    if (this.props.preview) {
      if (!(this.props.trackingDetails?.marketingAssets?.bannerDetails?.name == "demoBannerImageDiy")) {
        var marketingAsset = document.getElementById('marketingAsset');
        marketingAsset.classList.remove("diyFadeOut");
      }
    }

    if (isEmpty(this.state.marketingAssets) && this.props.trackingDetails.marketingAssetDIY) {
      this.setState({ marketingAssets: this.props.trackingDetails.marketingAssetDIY });
    }
    if (this.props.trackingDetails && this.props.trackingDetails.nextIntervalTime &&
      nextProps.trackingDetails.nextIntervalTime != this.props.trackingDetails.nextIntervalTime &&
      nextProps.trackingDetails.nextIntervalTime < this.props.trackingDetails.nextIntervalTime + 2000) {
      if (this.trackingDetailsRefreshTimeout) {
        clearTimeout(this.trackingDetailsRefreshTimeout);
        this.trackingDetailsRefreshTimeout = null;
      }

      this.trackingDetailsRefreshTimeout = setTimeout(this.getRefereshData.bind(this), this.props.trackingDetails.nextIntervalTime + 100);
    }
    // this will set overflow auto as set in componentDidMount after help modal close in case
    if (nextProps.showNeedHelpDialog && nextProps.showNeedHelpDialog != this.props.showNeedHelpDialog) {
      setTimeout(function () {
        document.body.style.overflowY = "auto";
      }.bind(this), 300);
    }


    this.checkAndFetchFeedbackInfo(nextProps);

  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
    clearInterval(this.intervalIdETa);
    clearInterval(this.previousJobsInfoIntervalId);
    if (this.trackingDetailsRefreshTimeout) {
      clearTimeout(this.trackingDetailsRefreshTimeout);
      this.trackingDetailsRefreshTimeout = null;
    }

  }
  hasQuickAction = () => {
    if ((this.props.trackingDetails && this.props.trackingDetails.processUpdateForm && !isEmpty(JSON.parse(this.props.trackingDetails.processUpdateForm).formList)) ||
      (this.props.trackingDetails && this.props.trackingDetails.help && !isEmpty(this.props.trackingDetails.help) &&
        (!isEmpty(JSON.parse(this.props.trackingDetails.help).helpLineContact) || !isEmpty(JSON.parse(this.props.trackingDetails.help).helpLineEmail))
      )) {
      return true;
    }
    return false;
  }
  checkPudoScreen = () => {
    this.checkPudoWithQuickAction();
    this.props.openPudoScreen();
  }
  getQuickAction = () => {
    return (
      this.hasQuickAction() &&
      <div className="">
        <NewHelpSection
          trackingDetails={this.props.trackingDetails}
          openNeedHelpProcessForm={this.props.openNeedHelpProcessForm}
        />


      </div>
    );
  }
  checkAndFetchFeedbackInfo = (prevProps) => {
    if (
      isEmpty(prevProps) ||
      isEmpty(prevProps.trackingDetails) ||
      isEmpty(this.props.trackingDetails) ||
      isEmpty(this.props.trackingDetails.feedback) ||
      isEmpty(this.props.url)
    ) {
      return;
    }

    if (!isEqual(prevProps.trackingDetails.feedback, this.props.trackingDetails.feedback) && !this.props.isFeedbackInfoFetch) {
      this.props.actions.fetchFeedbackInfo(this.props.url);
    }
  }

  getRefereshData() {

    // in case of live-tracking and fe movement no api call.
    if (this.props.url && this.props.trackingDetails && !(this.props.trackingDetails.liveMonitoringDto && this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO
      && this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList && this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0]
      && !isEmpty(this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0]) && this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList
      && !isEmpty(this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList))) {
      this.props.actions.getTrackingDetails(this.props.url, this.props.isReturn, this.props.trackingDetails);
    }
  }

  async getTripETA() {
    if (this.props.trackingDetails
      && this.props.trackingDetails.jobId
      && this.props.trackingDetails.layoutScreen
      && this.props.trackingDetails.layoutScreen.etaSource == "TRIP_ETA") {

      var timeFormat = processUpdateFormService.getTimeFormat(this.props.trackingDetails);
      this.props.actions.getTripETA(
        this.props.url,
        this.props.trackingDetails.jobId,
        this.props.trackingDetails.min,
        this.props.trackingDetails.max,
        this.props.trackingDetails.liveTrackingEnableTime,
        this.props.trackingDetails.browserTimeZone,
        timeFormat,
        this.props.trackingDetails.orderId,
      );
    }
  }



  openEventScreen = () => this.setState({ eventScreen: true });

  setMessageModal() {
    this.messageToFE = "";
    this.setState({
      message: !this.state.message,
      openSendMsgSnack: false
    });
  }

  handleSuccessSendMessage = () => {
    this.setState({
      message: false,
      openSendMsgSnack: true
    });
  }

  renderMarketingAsset = () => {
    var marketingAssets = isEmpty(this.state.marketingAssets) ? this.props.trackingDetails?.marketingAssets : this.state.marketingAssets;
    if (!isEmpty(marketingAssets)) {
      return (
        <div className="desktop-view-market-asset " style={{ marginBottom: "12px" }} >
          <div className="desktop-view-market-box-space" id="marketingAsset">
            {!isEmpty(marketingAssets.bannerDetails) && marketingAssets.enableBanner && (
              <Banner
                image={
                  marketingAssets.bannerDetails.url
                }
                link={
                  marketingAssets.bannerDetails.redirectUrl
                }
              />
            )}
          </div>
        </div>
      );
    };
  }

  renderStatusHeader = () => {
    return (
      <div className="mobileViewOrder position-rtv" >
        <div className="mobileViewDetails bg-white">
          {<OrderDetails
            trackingDetails={this.props.trackingDetails}
          />}
        </div>
      </div>
    );
  };

  renderSocialMedia = () => {

    if (
      !isEmpty(this.props.trackingDetails) ||
      !isEmpty(this.props.trackingDetails.customerInteractionThemeSettingsDTO) ||
      mediaLinkService.isFooterMediaLinkPresentInTheme(this.props.trackingDetails.customerInteractionThemeSettingsDTO)
    ) {
      return (
        <SocialMedia
          theme={this.props.trackingDetails.customerInteractionThemeSettingsDTO}
          layoutScreen={this.props.trackingDetails.layoutScreen}
          secondaryBgColor={this.props.secondaryBgColor}
        />
      );
    }

    if (this.props.trackingDetails && this.props.trackingDetails.layoutScreen) {
      let layoutScreen = this.props.trackingDetails.layoutScreen;
      if (!layoutScreen.facebook && !layoutScreen.twitter && !layoutScreen.youtube && !layoutScreen.insta && !layoutScreen.tiktok && !layoutScreen.linkedIn && !layoutScreen.pinterest
        && !(layoutScreen.extraInfo && layoutScreen.extraInfo[0] && layoutScreen.extraInfo[0].label)
        && !(layoutScreen.extraInfo && layoutScreen.extraInfo[1] && layoutScreen.extraInfo[1].label)) {
        return;
      }
    }
    return (
      <SocialMedia
        theme={this.props.trackingDetails.customerInteractionThemeSettingsDTO}
        layoutScreen={this.props.trackingDetails.layoutScreen}
        secondaryBgColor={this.props.secondaryBgColor}
      />
    );
  };


  getShipmentData = (url) => {
    if (url) {
      const start = url.indexOf("tracking?") + "tracking?".length;
      const end = url.indexOf("#/");
      url = url.substring(start, end);
    }
    this.props.actions.getTrackingDetails(url, this.props.isReturn, this.props.trackingDetails, true);
  }

  renderLayoutScreen = () => {
    return (
      <React.Fragment>
        <div className="layout-screen-mobile-wrapper newCiBackground">
          {this.props.trackingDetails.orderLevelLayoutScreenDTO?.attributeList && this.props.trackingDetails.orderLevelLayoutScreenDTO?.attributeList.length > 0 && this.renderStatusHeader()}
          {<ShipmentList
            containerConstants={containerConstants}
            orderData={this.props.trackingDetails.orderLevelLayoutScreenDTO}
            getShipmentData={this.getShipmentData}
            theme={this.props.trackingDetails.customerInteractionThemeSettingsDTO}
          />
          }

          {this.renderMarketingAsset()}
          {
            this.props.trackingDetails && this.props.trackingDetails.customerInteractionThemeSettingsDTO
            && this.props.trackingDetails.customerInteractionThemeSettingsDTO.quickAction &&
            this.getQuickAction()
          }
        </div>
        <div>
          {!this.props.universalTracking && this.renderSocialMedia()}
        </div>
      </React.Fragment>
    );
  };




  checkLandingPage = () => {
    if (this.props.trackingDetails && this.props.trackingDetails.processUpdateForm) {
      let processUpdateForm = JSON.parse(this.props.trackingDetails.processUpdateForm);
      if (processUpdateForm.formList) {
        const formList = processUpdateForm.formList;
        for (let i = 0; i < formList.length; i++) {
          if (formList[i].landingPage && formList[i].landingPage == true) {
            return (

              this.props.openProcessForm(i)
            );
          }
        }
      }
    }
    return this.renderLayoutScreen();

  }

  getScreen = () => {
    return this.checkLandingPage();
  }


  render() {
    console.log("HELLO BABB");
    return (
      <React.Fragment>
        {
          <div className="layout-screen-customize miller-knoll">
            {this.getScreen()}
          </div>
        }
      </React.Fragment>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...CustomerInteractionAction, ...LivetrackingAction, getPudoData },
      dispatch
    ),
  };
}

function mapStateToProps(state) {
  return {
    trackingDetails: state.customerInteractionReducer.trackingDetails,
    url: state.customerInteractionReducer.url,
    tripEta: state.customerInteractionReducer.tripEta,
    updateEta: state.customerInteractionReducer.updateEta,
    isJobRemoved: state.customerInteractionReducer.isJobRemoved,
    isFeedbackInfoFetch: state.customerInteractionReducer.isFeedbackInfoFetch,
    feedbackInfo: state.customerInteractionReducer.feedbackInfo,
    pudoPoints: state.pudoReducer.pudoPoints,
    previousJobCount: state.customerInteractionReducer.previousJobCount,
    previousJobsMessage: state.customerInteractionReducer.previousJobsMessage,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewMobileViewOrder);