const italianStrings = {
YourShipmentHasBeenDelivered: 'La spedizione è stata consegnata', 
DeliveredBy: 'Consegnata da', 
RateYourExperience: 'Valuta la tua esperienza', 
ThankYouForYourValuableFeedback: 'Grazie per il tuo prezioso feedback', 
YourFeedbackWillContinuallyHelpUsImproveOurServices: 'Il tuo feedback ci aiuterà a migliorare costantemente i nostri servizi', 
DeliveryFailed: 'Consegna non andata a buon fine', 
AddDeliveryPreferences: 'Aggiungi preferenze di consegna', 
YourDeliveryPreferenceAreSavedSuccessFully: 'Le tue preferenze di consegna sono state salvate con successo', 
WeAreUnableToTrackYourShipmentRightNow: 'Impossibile tracciare la tua spedizione adesso', 
PleaseUpdateLocationInsideCircle: 'Aggiornare la posizione all interno del cerchio', 
Ok: 'Ok', 
Update: 'Aggiorna', 
PickCurrentLocation: 'Seleziona posizione corrente', 
SearchFor: 'Cerca', 
ThisSchedulingLinkHasExpired: 'Questo link di programmazione è scaduto', 
WeWillShareANewLinkWithYouShortly: 'Condivideremo con te un nuovo link a breve', 
UhHo: 'Oh no!', 
NeedHelp: 'HAI BISOGNO DI AIUTO?', 
CallSupport: 'Chiama l Assistenza', 
EmailSupport: 'Invia un e-mail all Assistenza', 
DeliveryAt: 'Consegna presso', 
ContactNo: 'N. di contatto', 
TrackOnMap: 'Traccia sulla mappa', 
MessageToExecutive: 'Messaggio per il Direttore esecutivo', 
Cancel: 'Annulla', 
Send: 'Invia', 
Executive: 'Direttore esecutivo', 
Comments: 'Commenti (facoltativo)', 
SubmitFeedback: 'INVIA FEEDBACK', 
TrackingNo: 'N. di tracciamento', 
Details: 'Dettagli', 
Characters: 'Caratteri', 
Updating: 'aggiornamento in corso…..', 
OopsSomethingWentWrongPleaseTryAfterSomeTime: 'Ops! Qualcosa è andato storto, riprovare tra poco', 
NotListedAboveSendACustomMessage: 'Non presente nell elenco in alto, invia un messaggio personalizzato', 
ChangeDeliveryLocation: 'Cambia posizione di consegna', 
AttemptedBy: 'Tentativo eseguito da', 
DeliveredTo: 'Consegnato a', 
SendMessage: 'INVIA MESSAGGIO', 
VIEW_DETAILS: 'Visualizza dettagli', 
SAVE_LOCATION: 'SALVA POSIZIONE', 
LOCATION: 'posizione', 
SEARCH_ADDRESS: 'Cerca indirizzo', 
OpenHours: 'Orari di apertura', 
Note: 'Nota', 
Address: 'Indirizzo', 
Nearest: 'Il più vicino', 
ContactNumber: 'Numero di contatto', 
FAQS: 'FAQ', 
HowManyTimesCanIChangeMyPickUpPoint: 'Quante volte posso cambiare il mio punto di ritiro?', 
YouCanChangeItOnly: 'Puoi cambiarlo solo', 
GoBack: 'Indietro', 
SetAsPickupStore: 'Imposta come Negozio per il ritiro', 
MapView: 'Visualizzazione mappa', 
SearchForPickupPointNearYou: 'Cerca un punto di ritiro vicino a te', 
Arriving: 'In arrivo', 
LiveTrackingWillStart: 'Inizierà il tracciamento live', 
DriverTrackerWillGetActivatedOnceTheyAreOnWay: 'Il localizzatore del corriere si attiverà una volta su strada', 
TrackerTimeline: 'Cronologia di tracciamento', 
PM: 'AM', 
AM: 'PM', 
TodayAt: 'oggi alle', 
TomorrowAt: 'domani alle', 
Today: 'Oggi', 
Tomorrow: 'Domani', 
On: 'il', 
In: 'tra', 
Soon: 'presto', 
Min: 'min', 
Mins: 'minuti', 
Items: 'ARTICOLI', 
ViewDetails: 'Visualizza dettagli', 
DeliveryInformation: 'Informazioni sulla consegna', 
TrackingHistory: 'Cronologia tracciamento', 
StayConnected: 'Resta collegato', 
YouHaveAlreadyAttemptedToMakeAPaymentInLastFewMinutes: 'Hai già cercato di effettuare un pagamento negli ultimi minuti. Desideri annullare le transazioni precedenti?', 
No: 'NO', 
yes: 'SÌ', 
YourPackageWillBeDeliveredBy: 'Il pacco verrà consegnato entro', 
OrderDetails: 'Dettagli dell ordine', 
OrderInformation: 'Informazioni sull ordine', 
Thankyou: 'Grazie', 
LastAttempted: 'Ultimo tentativo', 
DeliveryBy: 'Consegnato da', 
MsgToFePageHeader: 'Invia istruzioni di consegna', 
MsgToFeSupportingText: 'Che cosa desideri dire al corriere?', 
AddYourMsgHere: 'Aggiungi qui il tuo messaggio', 
InstructionsSentSuccessfully: 'Istruzioni inviate con successo', 
SomethingWentWrongPlsTryAgain: 'Qualcosa è andato storto, riprovare.', 
times: 'volte', 
TrackMovementInRealTime: 'Traccia gli spostamenti in tempo reale', 
SetAsPickupStore: 'Imposta come Negozio per il ritiro', 
MapView: 'Visualizzazione mappa', 
DeliveredAt: 'Consegna presso', 
LastAttempted: 'Ultimo tentativo', 
Tomorrow: 'Domani',
WriteYourFeedbackOptional:"Scrivi il tuo feedback (facoltativo)",
ReturnOrder: "Return Order",
TheLinkYouAreTryingToOpenHasExpired: "Il collegamento che stai tentando di aprire è scaduto",
SelectAnAvailableTimeSlot:"Select an available time slot",
January:"JANUARY",
February:"FEBRUARY",
March :"MARCH",
April:"APRIL",
May:"MAY",
June:"JUNE",
July:"JULY",
August:"AUGUST",
September:"SEPTEMBER",
October:"OCTOBER",
November:"NOVEMBER",
December:"DECEMBER",
Monday:"MONDAY",
Tuesday:"TUESDAY",
Wednesday:"WEDNESDAY",
Thursday:"THURSDAY",
Friday:"FRIDAY",
Saturday:"SATURDAY",
Sunday:"SUNDAY",
ViewTimings: "View Timings",
HideTimings: "Hide Timings",
PleaseAnswerAllMandatoryQuestions:"Si prega di rispondere a tutte le domande obbligatorie",
HelpLineLink: 'Helpline Link',
ShipmentOf: "Spedizione%di",
OtherShipments: "Altri Spedizioni",

}
export default italianStrings;