const StoreClickCountLabelConstants= {
     Process_Update_Form_Submit            : "process_Update_Form_Submit",
     Cta_Count                             : "cta_count",
     Support_Count                         : "support_count",
     Banner_Count                          : "banner_count",
     Social_Media_Count                    : "social_media_count",
     Privacy_Policy_Count                  : "privacy_policy_count",
     Puf_Count                             : "puf_count",
     Carousel_Count                        : "carousel_count",
     Process_Udpate_Form_Submit_Desktop    : "processUpdateFormSubmitDesktop",
     Process_Udpate_Form_Submit_Mobile     : "processUpdateFormSubmitMobile",
     View_Details_Button                   : "viewDetailsButton",
     Call_Support_Button                   : "callSupportButton",
     Email_Button                          : "emailButton",
     Banner_Clicked                        : "bannerClicked",
     Facebook                              : "facebook",
     LinkedIn                              : "linkedIn",
     TikTok                                : "tiktok",
     Pinterest                             : "pinterest",
     Twitter                               : "twitter",
     Youtube                               : "youtube",
     Instagram                             : "instagram",
     Privacy_Policy                        : "privacyPolicy",
     Call_Button_Mobile                    : "callButtonMobile",
     Message_to_Fe                         : "messageToFe",
     Send_Message_to_Fe_Button             : "sendMessageToFeButton",
     Need_Help_Button                      : "needHelpButton",
     Carousel                              : "carousel",
     Process_Update_Form                   : "processUpdateForm",
     Ci_Link_Clicked                       : "ciLinkClicked",
     Submit_Feedback                       : "submitFeedback",
     Save_Button_Desktop                   : "saveButtonDesktop",
     GET_DIRECTION_DESKTOP                 : "getDirectionDesktop",
     GET_DIRECTION_PUDO_CARD               : "getDirectionPudoCard",
     CALL_STORE_DESKTOP                    : "callStoreDesktop",
     PUDO_SHOP_QUICK_ACTION                : "pudoShopQuickAction",
     GET_DIRECTION_MOBILE                  : "getDirectionMobileView",
     CALL_STORE_MOBILE                     : "callStoreMobileView",
     PUDO_FROM_NEED_HELP                   : "pudoFromNeedHelp",
     SELECT_BUTTON_PUDO_CARD               : "selectButtonPudoCard",
     CONFIRM_STORE                         : "confirmStore",
     LIST_VIEW_MOBILE                      : "listViewMobile",
     VIEW_MAP_MOBILE                       : "viewMapMobile",
     VIEW_ALL_PUDO                         : "viewAllPudo",
  };
  
  export default StoreClickCountLabelConstants