const germanString = {
    YourShipmentHasBeenDelivered: 'Ihre Sendung wurde zugestellt',
    DeliveredBy: 'Geliefert von',
    RateYourExperience:	'Bewerten Sie Ihre Erfahrung',
    ThankYouForYourValuableFeedback: 'Vielen Dank für Ihr wertvolles Feedback',
    YourFeedbackWillContinuallyHelpUsImproveOurServices:	'Ihr Feedback wird uns helfen, unsere Dienstleistungen kontinuierlich zu verbessern.',
    DeliveryFailed:	'Lieferung fehlgeschlagen',
    AddDeliveryPreferences:	'Lieferpräferenzen hinzufügen',
    YourDeliveryPreferenceAreSavedSuccessFully:	'Ihre Lieferpräferenzen wurden erfolgreich gespeichert',
    WeAreUnableToTrackYourShipmentRightNow:	'Wir können Ihre Sendung momentan nicht nachverfolgen',
    PleaseUpdateLocationInsideCircle:	'Bitte den Ort im Kreis aktualisieren',
    Ok:	'Ok',
    Update:	'Aktualisieren',
    PickCurrentLocation:	'Aktuellen Ort auswählen',
    SearchFor:	'Suchen nach',
    ThisSchedulingLinkHasExpired:	'Dieser Link zur Planung ist abgelaufen',
    WeWillShareANewLinkWithYouShortly:	'Wir werden Ihnen in Kürze einen neuen Link bereitstellen',
    UhHo:	'Oh ho!',
    NeedHelp:	'BRAUCHEN SIE HILFE',
    CallSupport:	'Support anrufen',
    EmailSupport:	'Support emailen',
    DeliveryAt:	'Zustellung bei',
    ContactNo:	'Kontaktnummer',
    TrackOnMap:	'Auf Karte verfolgen',
    MessageToExecutive:	'Mitteilung an den Geschäftsführer',
    Cancel:	'Abbrechen',
    Send:	'Senden',
    Executive:	'Geschäftsführer',
    Comments:	'Kommentare (optional)',
    SubmitFeedback:	'FEEDBACK SENDEN',
    TrackingNo:	'Auftragsnummer',
    Details:	'Details',
    Characters:	'Zeichen',
    Updating:	'Wird aktualisiert …',
    OopsSomethingWentWrongPleaseTryAfterSomeTime:	'Ups! Etwas ist schief gelaufen, bitte später erneut versuchen.',
    NotListedAboveSendACustomMessage:	'Oben nicht aufgeführt, individuelle Nachricht senden',
    ChangeDeliveryLocation:	'Zustellungsort ändern',
    AttemptedBy:	'Versucht von',
    DeliveredTo:	'Zugestellt an',
    SendMessage:	'MITTEILUNG SENDEN',
    VIEW_DETAILS:	'Details anzeigen',
    SAVE_LOCATION:	'ORT SPEICHERN',
    LOCATION:	'ORT ',
    SEARCH_ADDRESS:	'Adresse suchen',
    OpenHours:	'Öffnungszeiten',
    Note:	'Hinweis',
    Address:	'Adresse',
    Nearest:	'Nächstgelegen',
    ContactNumber:	'Kontaktnummer',
    FAQS:	'Häufige Fragen',
    HowManyTimesCanIChangeMyPickUpPoint:	'Wie oft kann ich meinen Abholort ändern?',
    YouCanChangeItOnly:	'Sie können ihn nur',
    GoBack:	'Zurückgehen',
    SetAsPickupStore:	'Als Abholshop festlegen',
    MapView:	'Kartenansicht',
    SearchForPickupPointNearYou:	'Nach Abholort in Ihrer Nähe suchen',
    Arriving:	'Wird geliefert',
    LiveTrackingWillStart:	'Life-Verfolgung beginnt',
    DriverTrackerWillGetActivatedOnceTheyAreOnWay:	'Tracker des Fahrers wird aktiviert, sobald er losfährt.',
    TrackerTimeline:	'Verfolgungszeitraum',
    PM:	'Nachmittags',
    AM:	'Vormittags',
    TodayAt:	'Heute um',
    TomorrowAt:	'Morgen um',
    Today:	'Heute',
    Tomorrow:	'Morgen',
    On:	'am',
    In:	'in',
    Soon:	'bald',
    Min:	'Minute',
    Mins:	'Minuten',
    Items:	'ARTIKEL',
    ViewDetails:	'Details anzeigen',
    DeliveryInformation:	'Lieferinformationen',
    TrackingHistory:	'Verfolgungsgeschichte',
    StayConnected:	'Verbunden bleiben',
    YouHaveAlreadyAttemptedToMakeAPaymentInLastFewMinutes:	'Sie haben in den letzten Minuten versucht, eine Zahlung vorzunehmen. Möchten Sie frühere Transaktionen stornieren?',
    No:	'Nein',
    yes:	'JA',
    YourPackageWillBeDeliveredBy:	'Ihr Paket wurde zugestellt von',
    OrderDetails:	'Bestellungsdetails',
    OrderInformation:	'Bestellinformationen',
    ThankYou:	'Vielen Dank',
    LastAttempted:	'Zuletzt versucht',
    DeliveryBy:	'Zugestellt von',
    MsgToFePageHeader:	'Lieferanweisungen senden',
    MsgToFeSupportingText:	'Was möchten Sie dem Fahrer sagen?',
    AddYourMsgHere:	'Fügen Sie Ihre Mitteilung hier ein',
    InstructionsSentSuccessfully:	'Anweisungen wurden erfolgreich gesendet',
    SomethingWentWrongPlsTryAgain:	'Etwas ist schief gelaufen, versuchen Sie es erneut',
    times:	'Mal',
    TrackMovementInRealTime:	'Bewegung in Echtzeit verfolgen',
    DeliveredAt:	'Zugestellt an',
    LastAttempted:	'Letzter Versuch',
    Tomorrow:	'Morgen',
    Submit: 'Senden',
	WriteYourFeedbackOptional:'Feedback eintragen (optional)',
	OrderInformation:'Bestellinformationen',
	TrackingTimeline:'Verfolgungs-Timeline',
    Pickuppointisupdated: 'Abholpunkt wird aktualisiert',
    Hour: 'Stunde',
    Hours:'Std',
    WriteYourFeedbackOptional:"Schreiben Sie Ihr Feedback (optional)",
    ReturnOrder: "Return Order",
    TheLinkYouAreTryingToOpenHasExpired: "Der Link, den Sie öffnen möchten, ist abgelaufen    ",
    SelectAnAvailableTimeSlot:"Select an available time slot",
    January:"JANUARY",
    March :"MARCH",
    February:"FEBRUARY",
    May:"MAY",
    April:"APRIL",
    July:"JULY",
    June:"JUNE",
    August:"AUGUST",
    September:"SEPTEMBER",
    October:"OCTOBER",
    November:"NOVEMBER",
    December:"DECEMBER",
    Monday:"MONDAY",
    Tuesday:"TUESDAY",
    Wednesday:"WEDNESDAY",
    Thursday:"THURSDAY",
    Friday:"FRIDAY",
    Saturday:"SATURDAY",
    Sunday:"SUNDAY",
    ViewTimings: "View Timings",
    HideTimings: "Hide Timings",
    PleaseAnswerAllMandatoryQuestions:"Bitte beantworten Sie alle Pflichtfragen",
    HelpLineLink: 'Helpline Link',
    ShipmentOf: "Lieferung%von",
    OtherShipments: "Andere Sendungen",
}

export default germanString;